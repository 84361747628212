<template>
  <a-dropdown style="margin-right: 10px" placement="bottomRight">
    <div class="menu-icon flex-ct">
      <span class="mr4">{{ merchantName }}</span>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <template>
          <a-row class="box">
            <a-col
              class="boxText"
              v-for="(item, index) in dataList"
              @click="changeMerchant(item)"
              :key="index"
              :span="6"
            >
              {{ item.name }}
            </a-col>
          </a-row>
        </template>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import api from '@/service/api'
import http from '@/service/axios'
import { mapActions, mapMutations } from 'vuex'
import { Modal } from 'ant-design-vue'
import { setCookie } from '@/common/cookie'
import { systemName } from '@/config/config.js'
export default {
  data() {
    return {
      merchantName: 0,
      dataList: [],
      merchantCode: '',
    }
  },
  mounted() {
    if (systemName === 'FAST_BOOKS') {
      this.getShopList()
    }
    this.merchantName = localStorage.getItem('merchantName')
  },

  methods: {
    ...mapMutations(['setState']),
    ...mapActions(['getShopInfoList']),
    async changeMerchant(item) {
      try {
        let res = await http({
          url: api.switchMerchant,
          data: {
            merchantCode: item.code,
          },
        })
        this.merchantName = item.name
        localStorage.setItem('merchantName', item.name)
        // console.log(res.result.sessionId)
        // setCookie({ d_session_id: res.result.sessionId })
        this.$success(this.$t('切换成功'))
        this.login(res.result)
      } catch (err) {
        console.log(err)
      }
    },
    login(userInfo) {
      setCookie({ reportPlatform: systemName })
      setCookie({ scmPlatform: 'SCM' })
      this.menuList = userInfo.menuList
      this.buList = userInfo.buList
      this.setState({
        menuList: userInfo.menuList,
      })
      this.merchantCode = userInfo.merchantCode
      localStorage.setItem('userAccount', userInfo.userName)
      // localStorage.setItem('merchantName', userInfo.merchantName)
      localStorage.setItem('merchantCode', this.merchantCode)
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      this.getShopList()
    },
    async getShopList() {
      let res = await http({
        url: api.getNewShopInfoList,
        data: {
          status: 1,
          merchantCode: localStorage.getItem('merchantCode'),
        },
      })
      let list = res.result.list || []
      this.setState({
        shopList: list,
        currentShop: list?.[0] || {},
      })
      this.queryIdaasAppList()
      // this.setParty(list[0])
      for (let i = 0; i < list.length; i++) {
        if (list[i].authStatus === 'AUTH_EXPIRED' && list[i].authExpiredDays < 5) {
          setTimeout(() => {
            Modal.confirm({
              title: list[i].shopName + this.$t(`店铺授权快失效了，快去重新授权吧`),
              // content: this.$t(`重新授权`),
              okText: this.$t(`重新授权`),
              cancelText: this.$t(`取消`),
              onOk: () => {
                localStorage.removeItem('partyList')
                this.$router.push('/shop_web/shopManage?shopName=' + list[i].shopName)
              },
            })
          }, 1500)
          break
        }
      }
    },
    setParty(party) {
      party = party || this.buList[0]
      if (!party) {
        this.$message.warning(this.$t('业务组为空'))
        return
      }
      http({
        url: api.switchParty,
        type: 'post',
        data: { buId: party.departmentId || party.id },
        success: async (res) => {
          const userSession = res.result
          party = userSession.currentBu || party
          party.partyId = party.id
          party.partyName = party.name
          userSession.currentParty = party //接口换了 只好前端来兼容
          this.setState({
            userSession,
          })
          // this.$router.go(0)
        },
      })
    },
    async getList() {
      try {
        let res = await http({
          url: api.merchantList,
          data: {
            appCodes: ['ORDER', 'PLYMOUTH', 'ULTRA_WMS', 'OMS'],
          },
        })
        this.dataList = res.result.list
      } catch (err) {
        return Promise.resolve()
      }
    },
    //判断是不是新的订单服务
    async queryIdaasAppList() {
      try {
        let res = await http({
          url: api.queryIdaasAppList,
          data: {
            merchantCode: localStorage.getItem('merchantCode'),
          },
        })
        let list = res.result,
          obj = {}
        list.forEach((item) => {
          obj[item.appCode] = item
        })
        localStorage.setItem('currentIdaasApp', JSON.stringify(obj))
      } catch (err) {
        return Promise.resolve()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.active {
  color: $primary-color;
}
.box {
  background-color: white;
  margin: -10px;
  border-radius: 4px;
  width: 700px;
  overflow: hidden;
  padding: 12px 8px;
  max-height: 500px;
  overflow-y: auto;
}
.boxText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.boxText:hover {
  background-color: #d1e8fd;
}
</style>
