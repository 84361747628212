<template>
  <a-dropdown>
    <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
      <span class="current-lang">{{ currentLangLabel }}</span>
      <a-icon type="global" />
    </a>
    <a-menu slot="overlay" @click="changeLang">
      <a-menu-item key="zh">
        <span>中文</span>
      </a-menu-item>
      <a-menu-item key="en">
        <span>English</span>
      </a-menu-item>
      <a-menu-item key="th">
        <span>ไทย</span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
const LANG_LABELS = {
  zh: '中文',
  en: 'English',
  th: 'ไทย',
}

export default {
  name: 'LangSwitch',
  computed: {
    currentLangLabel() {
      return LANG_LABELS[this.$getLocale()]
    },
  },
  methods: {
    changeLang({ key }) {
      this.$setLocale(key)
      this.$nextTick(() => {
        history.go(0)
      })
    },
  },
}
</script>

<style scoped>
.current-lang {
  margin-right: 8px;
}
.ant-dropdown-link {
  color: #fff;
  margin: 0 12px;
}
</style>
